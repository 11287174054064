// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import lang from './lang'
import navbar from './navbar'
import layout from './layout'

const rootReducer = combineReducers({
  auth,
  lang,
  navbar,
  layout,
})

export default rootReducer
