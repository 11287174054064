const  Logo = ({width,height}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 901 901">
            <defs>
                <clipPath id="clip-Newnew_copy_1">
                    <rect width={901} height={901} />
                </clipPath>
            </defs>
            <g id="Newnew_copy_1" data-name="Newnew copy – 1" clipPath="url(#clip-Newnew_copy_1)">
                <g id="Group_8" data-name="Group 8" transform="translate(-153 -81.422)">
                    <g id="Group_2" data-name="Group 2">
                        <g id="Group_1" data-name="Group 1">
                            <path id="Path_1" data-name="Path 1" d="M440.569,365.093a31.071,31.071,0,0,1-7.114,3.713,15.273,15.273,0,0,1-6.606.9,12.961,12.961,0,0,1-6.038-2.241,21.021,21.021,0,0,1-5.457-5.726l-16.442-24.13A6.078,6.078,0,0,0,397,335.69a3.82,3.82,0,0,0-1.986-.535,4.839,4.839,0,0,0-2.028.514,16.3,16.3,0,0,0-2.051,1.2l-.789.537-1.778-2.61,18.09-12.325,1.778,2.61-.819.558a14.879,14.879,0,0,0-1.869,1.5,4.734,4.734,0,0,0-1.2,1.729,3.951,3.951,0,0,0-.173,2.1,6.784,6.784,0,0,0,1.182,2.616l16.525,24.251a14.741,14.741,0,0,0,3.906,4.072,9.57,9.57,0,0,0,4.254,1.612,10.438,10.438,0,0,0,4.457-.5,18.194,18.194,0,0,0,4.546-2.3,16.188,16.188,0,0,0,4.163-3.9,10.774,10.774,0,0,0,1.872-4.231,9.709,9.709,0,0,0-.177-4.368,14.161,14.161,0,0,0-2.035-4.325l-16.854-24.736a6.069,6.069,0,0,0-1.91-1.921,3.809,3.809,0,0,0-1.986-.535,4.831,4.831,0,0,0-2.027.514,16.27,16.27,0,0,0-2.051,1.2l-.789.538-1.778-2.61,15.6-10.63,1.778,2.61-.819.559a14.931,14.931,0,0,0-1.869,1.495,4.755,4.755,0,0,0-1.2,1.729,3.952,3.952,0,0,0-.172,2.1,6.753,6.753,0,0,0,1.183,2.617l16.771,24.615a18.091,18.091,0,0,1,2.809,6.242,13.245,13.245,0,0,1-.061,6.174,15.786,15.786,0,0,1-2.991,5.882A26.082,26.082,0,0,1,440.569,365.093Z" fill="#222" />
                            <path id="Path_2" data-name="Path 2" d="M494.682,325.124a19.5,19.5,0,0,0,5.241,2.589,6.322,6.322,0,0,0,4.435-.351l.2-.091,1.3,2.878-.97.439a53.1,53.1,0,0,1-5.377,2.147,12.446,12.446,0,0,1-3.907.677,7.811,7.811,0,0,1-3.306-.763,26.892,26.892,0,0,1-3.614-2.156l-16.243-11.241-5.689,2.57,6.381,14.125a6.083,6.083,0,0,0,1.548,2.222,3.559,3.559,0,0,0,1.849.837,5.19,5.19,0,0,0,2.071-.189,16.249,16.249,0,0,0,2.226-.825l.9-.409,1.3,2.879-19.948,9.012-1.3-2.878.87-.393a16.353,16.353,0,0,0,2.091-1.126,5.191,5.191,0,0,0,1.511-1.429,3.561,3.561,0,0,0,.594-1.94,6.075,6.075,0,0,0-.645-2.631l-15.332-33.939a6.08,6.08,0,0,0-1.549-2.222,3.819,3.819,0,0,0-1.864-.871,4.839,4.839,0,0,0-2.085.157,16.3,16.3,0,0,0-2.228.824l-.87.393-1.3-2.878,18.441-8.332q8.9-4.021,14.744-2.691T483,297.476a13.235,13.235,0,0,1,1.283,5.2,13.091,13.091,0,0,1-.716,4.615,14.9,14.9,0,0,1-2.112,3.977,23.354,23.354,0,0,1-2.889,3.24ZM465.3,318.609l4.82-2.177a17.643,17.643,0,0,0,4.718-2.938,8.041,8.041,0,0,0,2.306-3.46,8.6,8.6,0,0,0,.211-4.125,20.682,20.682,0,0,0-1.6-4.921,18.257,18.257,0,0,0-2.678-4.412,7.722,7.722,0,0,0-3.263-2.314,8.236,8.236,0,0,0-4.1-.284,20.829,20.829,0,0,0-5.187,1.677l-4.652,2.1Z" fill="#222" />
                            <path id="Path_3" data-name="Path 3" d="M538.712,266.417a30.721,30.721,0,0,1,6.7-.679,16.052,16.052,0,0,1,4.995.822,8.08,8.08,0,0,1,3.3,2.077,6.311,6.311,0,0,1,1.571,3.094,4.74,4.74,0,0,1-.059,2.241,4.82,4.82,0,0,1-1.028,1.889,6.46,6.46,0,0,1-1.831,1.432,8.735,8.735,0,0,1-2.467.866,13.587,13.587,0,0,0-1.132-3.332,8.842,8.842,0,0,0-2.083-2.75,7.916,7.916,0,0,0-3.1-1.666,8.925,8.925,0,0,0-4.221-.039,15.411,15.411,0,0,0-6.791,2.943,12.533,12.533,0,0,0-3.866,5.414,21.464,21.464,0,0,0-1.223,7.771,53.392,53.392,0,0,0,1.134,10.016,44.7,44.7,0,0,0,2.664,8.869,21.482,21.482,0,0,0,4.14,6.5,13.573,13.573,0,0,0,5.68,3.606,14.354,14.354,0,0,0,7.293.233,19.978,19.978,0,0,0,4.668-1.473,15.864,15.864,0,0,0,3.38-2.115,13.705,13.705,0,0,0,2.393-2.554,19.843,19.843,0,0,0,1.674-2.787,3.212,3.212,0,0,1,1.224.88,3.665,3.665,0,0,1,.755,1.722,5.335,5.335,0,0,1-.385,3.054,9.148,9.148,0,0,1-2.372,3.225,18.633,18.633,0,0,1-4.544,2.946,29.127,29.127,0,0,1-6.942,2.187,23.192,23.192,0,0,1-10.077-.015,19.482,19.482,0,0,1-8.1-4.117,25.027,25.027,0,0,1-5.976-7.722,40.8,40.8,0,0,1-3.649-10.808,38.2,38.2,0,0,1-.668-11.251,24.6,24.6,0,0,1,2.784-9.43,20.625,20.625,0,0,1,6.3-7.021A25.416,25.416,0,0,1,538.712,266.417Z" fill="#222" />
                            <path id="Path_4" data-name="Path 4" d="M567.735,316.461l-.128-3.156.955-.038a14.864,14.864,0,0,0,2.4-.281,4.879,4.879,0,0,0,1.933-.832,3.776,3.776,0,0,0,1.255-1.686,7.016,7.016,0,0,0,.363-2.845l-1.481-36.551a7.018,7.018,0,0,0-.59-2.806,3.781,3.781,0,0,0-1.388-1.58,4.882,4.882,0,0,0-1.993-.673,14.866,14.866,0,0,0-2.411-.086l-.955.039-.128-3.156,21.872-.886.128,3.156-.991.04a16.3,16.3,0,0,0-2.36.261,4.85,4.85,0,0,0-1.935.795A3.823,3.823,0,0,0,581,267.79a6.077,6.077,0,0,0-.388,2.681l1.687,41.652,14.825-.6a6.3,6.3,0,0,0,2.856-.722,5.831,5.831,0,0,0,1.9-1.657,7.449,7.449,0,0,0,1.1-2.232,11.631,11.631,0,0,0,.471-2.445l.507-5.645,3.927-.159.139,16.168Z" fill="#222" />
                            <path id="Path_5" data-name="Path 5" d="M664.247,294.193a39.008,39.008,0,0,1-3,11.016,24.752,24.752,0,0,1-5.65,8.076,20.344,20.344,0,0,1-8.086,4.608,24.388,24.388,0,0,1-10.273.654,25.431,25.431,0,0,1-10.085-3.321,19.626,19.626,0,0,1-6.59-6.531,24.507,24.507,0,0,1-3.231-9.258,41.22,41.22,0,0,1,.02-11.462,40.807,40.807,0,0,1,2.928-11.043,24.111,24.111,0,0,1,5.5-8.04,19.539,19.539,0,0,1,8.079-4.554,25.884,25.884,0,0,1,10.634-.588,24.345,24.345,0,0,1,9.723,3.255,20.3,20.3,0,0,1,6.594,6.495,24.569,24.569,0,0,1,3.384,9.222A39.094,39.094,0,0,1,664.247,294.193Zm-38.638-5.062a54.2,54.2,0,0,0-.476,9.994,22.1,22.1,0,0,0,1.677,7.7,12.572,12.572,0,0,0,4.114,5.206,16.608,16.608,0,0,0,14.094,1.846,12.435,12.435,0,0,0,5.3-3.973,22.141,22.141,0,0,0,3.568-7.014,61.993,61.993,0,0,0,2.591-19.775,21.972,21.972,0,0,0-1.643-7.679,12.439,12.439,0,0,0-4.064-5.163,14.567,14.567,0,0,0-6.806-2.484,14.883,14.883,0,0,0-7.27.641,12.555,12.555,0,0,0-5.348,3.929,21.644,21.644,0,0,0-3.62,6.99A54.2,54.2,0,0,0,625.609,289.131Z" fill="#222" />
                            <path id="Path_6" data-name="Path 6" d="M680.51,327.7a24.537,24.537,0,0,1-5.982-2.615,14.391,14.391,0,0,1-3.877-3.417,8.932,8.932,0,0,1-1.757-4.023,9.724,9.724,0,0,1,.335-4.407,4.932,4.932,0,0,1,2.417-3.106,5.481,5.481,0,0,1,4.253-.172,20.289,20.289,0,0,0-.622,4.531,11.739,11.739,0,0,0,.634,4.183A8.98,8.98,0,0,0,678.1,322.1a9.54,9.54,0,0,0,4.037,2.257q4.851,1.467,8.283-.046a8.764,8.764,0,0,0,4.761-5.909,10.436,10.436,0,0,0,.49-3.65,8.034,8.034,0,0,0-1.019-3.358,16.4,16.4,0,0,0-2.682-3.5,54.49,54.49,0,0,0-4.541-4.079,40.24,40.24,0,0,1-4.949-4.7,18.916,18.916,0,0,1-3.1-4.735,13.439,13.439,0,0,1-1.169-5.015,16.976,16.976,0,0,1,.8-5.553,13.05,13.05,0,0,1,2.846-5.164,12.183,12.183,0,0,1,4.527-3.1,15.659,15.659,0,0,1,5.762-1.039,22.466,22.466,0,0,1,6.559,1.025,23.246,23.246,0,0,1,5.528,2.4A15.227,15.227,0,0,1,707.91,281a8.762,8.762,0,0,1,1.838,3.376,5.986,5.986,0,0,1-.023,3.312,4.326,4.326,0,0,1-2.587,3.036,6.9,6.9,0,0,1-4.488-.072,14.41,14.41,0,0,0,.6-3.444,8.5,8.5,0,0,0-.471-3.327,7.517,7.517,0,0,0-1.863-2.847,8.579,8.579,0,0,0-3.588-1.968,11.548,11.548,0,0,0-3.884-.541,7.973,7.973,0,0,0-3.259.817,7.486,7.486,0,0,0-2.5,2.063,9.5,9.5,0,0,0-1.619,3.2,12.169,12.169,0,0,0-.581,3.949,8.019,8.019,0,0,0,.989,3.522,15.912,15.912,0,0,0,2.708,3.543,52.279,52.279,0,0,0,4.534,4.038,48.069,48.069,0,0,1,4.812,4.449,20.264,20.264,0,0,1,3.229,4.487,13.231,13.231,0,0,1,1.434,4.77,13.445,13.445,0,0,1-.589,5.251,15.014,15.014,0,0,1-3.093,5.722,13.1,13.1,0,0,1-4.945,3.473,16.817,16.817,0,0,1-6.446,1.139A26.882,26.882,0,0,1,680.51,327.7Z" fill="#222" />
                            <path id="Path_7" data-name="Path 7" d="M717.985,336.747l13.112,6.209a8.441,8.441,0,0,0,2.984.864,6.057,6.057,0,0,0,2.482-.308,6.191,6.191,0,0,0,2.037-1.21,9.619,9.619,0,0,0,1.611-1.858l1.848-2.7,3.551,1.682-6.091,11.663-36.448-17.259,1.353-2.854.862.408a16.337,16.337,0,0,0,2.212.865,5.206,5.206,0,0,0,2.066.227,3.567,3.567,0,0,0,1.865-.8,6.089,6.089,0,0,0,1.588-2.194l15.812-33.394a6.98,6.98,0,0,0,.779-2.76,3.775,3.775,0,0,0-.495-2.043,4.889,4.889,0,0,0-1.454-1.521,14.936,14.936,0,0,0-2.1-1.2l-.863-.409,1.353-2.854,34.256,16.221-5.3,12.041-3.519-1.666,1.052-3.078a10.085,10.085,0,0,0,.5-2.385,5.9,5.9,0,0,0-.254-2.294,5.628,5.628,0,0,0-1.219-2.061,7.508,7.508,0,0,0-2.393-1.682l-11.419-5.407-8.567,18.091,16.4,7.765-1.572,3.319-16.4-7.765Z" fill="#222" />
                            <path id="Path_8" data-name="Path 8" d="M768.114,359.768a6.763,6.763,0,0,0-1.183,2.615,3.935,3.935,0,0,0,.171,2.1,4.728,4.728,0,0,0,1.2,1.729,14.794,14.794,0,0,0,1.869,1.5l1.426.973-1.781,2.609L750.488,358.1l1.781-2.609,1.425.973a16.362,16.362,0,0,0,2.051,1.2,5.2,5.2,0,0,0,2.006.545,3.557,3.557,0,0,0,1.966-.5,6.077,6.077,0,0,0,1.911-1.92l23.535-34.494-5.946-4.058a6.853,6.853,0,0,0-2.57-1.153,5.044,5.044,0,0,0-2.272.072,5.556,5.556,0,0,0-1.987,1.023,12.9,12.9,0,0,0-1.745,1.678l-2.156,2.441-3.216-2.194,7.714-10.654,34.981,23.867-7.108,11.068-3.215-2.194,1.518-2.876a12.112,12.112,0,0,0,.91-2.247,5.985,5.985,0,0,0,.228-2.224,4.846,4.846,0,0,0-.762-2.142,7.064,7.064,0,0,0-2.057-2l-6.037-4.12Z" fill="#222" />
                        </g>
                    </g>
                    <g id="Group_7" data-name="Group 7">
                        <g id="Group_6" data-name="Group 6">
                            <g id="Group_5" data-name="Group 5">
                                <g id="Group_3" data-name="Group 3">
                                    <path id="Path_9" data-name="Path 9" d="M453.241,801.919H436.156l3.817-28.9h17.084Z" fill="#092121" />
                                </g>
                                <g id="Group_4" data-name="Group 4">
                                    <path id="Path_10" data-name="Path 10" d="M746.76,801.919h17.084l-3.817-28.9H742.943Z" fill="#092121" />
                                </g>
                            </g>
                        </g>
                        <path id="Rectangle_1" data-name="Rectangle 1" d="M142.307,0H250.264A142.307,142.307,0,0,1,392.57,142.307V345.616a30.6,30.6,0,0,1-30.6,30.6H30.6A30.6,30.6,0,0,1,0,345.616V142.307A142.307,142.307,0,0,1,142.307,0Z" transform="translate(403.715 398.081)" fill="#d3485d" />
                        <path id="Path_11" data-name="Path 11" d="M591.19,736.294H450.259a11.376,11.376,0,0,1-11.376-11.376V539.773a125.493,125.493,0,0,1,42.371-94.14,125.644,125.644,0,0,0-53.216,102.743V733.52A11.377,11.377,0,0,0,439.415,744.9H580.346a11.354,11.354,0,0,0,11-8.619C591.293,736.279,591.244,736.294,591.19,736.294Z" fill="#fff" opacity="0.4" style={{ mixBlendMode: 'overlay', isolation: 'isolate' }} />
                        <path id="Path_12" data-name="Path 12" d="M771.617,641.423H630.686a11.377,11.377,0,0,1-11.376-11.377V444.9a126.349,126.349,0,0,1,1.848-21.477c-3.106.344-6.3.56-9.347,1.209a126.037,126.037,0,0,0-3.346,28.87V638.649a11.377,11.377,0,0,0,11.377,11.377H760.773a11.354,11.354,0,0,0,11-8.619C771.72,641.407,771.671,641.423,771.617,641.423Z" fill="#fff" opacity="0.4" style={{ mixBlendMode: 'overlay', isolation: 'isolate' }} />
                        <path id="Path_13" data-name="Path 13" d="M513.233,431.395a125.873,125.873,0,0,0-85.195,119.116V735.656a11.377,11.377,0,0,0,11.377,11.376H580.346a11.377,11.377,0,0,0,11.377-11.376V441.162a16.4,16.4,0,0,0-3.9-10.641" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit={10} strokeWidth="10.485" />
                        <path id="Path_14" data-name="Path 14" d="M608.278,735.656V441.162a16.47,16.47,0,0,1,16.47-16.47h21.394a125.82,125.82,0,0,1,125.82,125.819V735.656" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit={10} strokeWidth="10.485" />
                        <line id="Line_1" data-name="Line 1" x2="163.684" transform="translate(612.64 652.161)" fill="none" stroke="#fff" strokeMiterlimit={10} strokeWidth="10.485" />
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="8.289" cy="8.289" r="8.289" transform="translate(636.36 615.792)" fill="#e6d8cd" />
                        <circle id="Ellipse_2" data-name="Ellipse 2" cx="8.289" cy="8.289" r="8.289" transform="translate(548.304 615.792)" fill="#e6d8cd" />
                        <line id="Line_2" data-name="Line 2" x2="67.064" transform="translate(656.588 703.958)" fill="none" stroke="#e6d8cd" strokeLinecap="round" strokeMiterlimit={10} strokeWidth="8.542" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Logo